$('#rollLinks').localScroll({
    target:'body',
    offset: -97
});

$('.botaoResponsivo').on('click', function(){
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $(".linhasmr").addClass("ativo");
    } else {
        $(".linhasmr").removeClass("ativo");
    }
    return false;
});